import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotifierTypes } from "common/enums";

const initialState: NotificationState = {
  message: "",
  type: NotifierTypes.None,
  identifier: new Date()
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification(state, action: PayloadAction<NotificationState>) {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.identifier = new Date();
    },
    clearNotification(state) {
      state = initialState;
    }
  }
});

export const { setNotification, clearNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
