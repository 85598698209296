import { Action, ThunkAction } from "@reduxjs/toolkit";
import { NotifierTypes } from "common/enums";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setCompanyLogo, setCompanyWhiteLogo, setIsLoading, setIsWhitleLogoLoading } from "store/slices/branding-slice";
import { setNotification } from "store/slices/notification-slice";

export const getCompanyLogo = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  dispatch(setIsLoading(true));
  Axios.get(`${API.SUITE_LOGO.COMPANY_LOGO}`, {
    method: "GET",
    credentials: "include"
  })
    .then((response: any) => {
      dispatch(setCompanyLogo(response));
    })
    .catch((error: any) => {
      dispatch(setIsLoading(false));
      dispatch(setNotification({ message: error.message, type: NotifierTypes.Error }));
    });
};

export const getCompanyWhiteLogo = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  dispatch(setIsWhitleLogoLoading(true));
  Axios.get(`${API.SUITE_LOGO.COMPANY_WHITE_LOGO}`, {
    method: "GET",
    credentials: "include"
  })
    .then((response: any) => {
      dispatch(setCompanyWhiteLogo(response));
    })
    .catch((error: any) => {
      dispatch(setIsLoading(false));
      dispatch(setNotification({ message: error.message, type: NotifierTypes.Error }));
    });
};
