import { FC } from "react";
import Modal from "./modal/Modal";
import { useLogout } from "common/hooks/useLogout";
import { UserManagementConstants } from "helper/constants";
import Button, { ButtonType } from "./button/Button";

interface LogoutModalProps {
  show: boolean;
  onHide: () => void;
}

const LogoutModal: FC<LogoutModalProps> = ({ show, onHide }) => {
  const handleLogout = useLogout();

  const modalFooter = () => {
    return (
      <>
        <Button buttonType={ButtonType.Light} onClick={onHide}>
          {UserManagementConstants.CancelButtonText}
        </Button>
        <Button buttonType={ButtonType.Danger} onClick={handleLogout}>
          {UserManagementConstants.LogoutButtonText}
        </Button>
      </>
    );
  };
  
  return (
    <Modal title={UserManagementConstants.LogoutTitle} isOpen={show} onClose={onHide} footer={modalFooter()}>
      {UserManagementConstants.UserLogoutWarnigMessage}
    </Modal>
  );
};

export default LogoutModal;
