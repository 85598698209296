/* Contains all the routes of the application */
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SigninOidc from "oidc-client/SigninOidc";
import SignoutOidc from "oidc-client/SignoutOidc";
import SilentSigninOidc from "oidc-client/SilentSigninOidc";
import Login from "common/components/pages/Login";
import PreLogin from "common/components/pages/PreLogin";
import LoadingView from "common/components/pages/LoadingScreen";
import AppLayout from "common/components/pages/AppLayout";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import AppRoute from "route";
import { Toaster } from "common/components/toaster/AppNotifier";
import RBACRoute from "./RBACRoute";

const BookmarkTemplatePage = React.lazy(() => import("pages/bookmark-template"));
const SettingsPage = React.lazy(() => import("pages/settings"));

const AppRoutes: React.FC = () => {
  const authentication: any = useAppSelector((state: AppState) => state.authReducer?.authentication);
  const isLayoutLoading: any = useAppSelector((state: AppState) => state.layoutReducer?.isLoading);

  if (authentication?.user) {
    document.cookie = "id_Token=token";
    return (
      <>
        <AppLayout>
          <Toaster />
          <Suspense fallback={<LoadingView />}>
            {isLayoutLoading ? (
              <LoadingView />
            ) : (
              <Routes>
                <Route path={AppRoute.BOOKMARK_TEMPLATE} element={<RBACRoute component={BookmarkTemplatePage} />} />
                <Route path={AppRoute.SETTINGS} element={<RBACRoute component={SettingsPage} />} />
                <Route path="/" element={<Navigate to={AppRoute.BOOKMARK_TEMPLATE} />} />
                <Route path="*" element={<Navigate to={AppRoute.BOOKMARK_TEMPLATE} />} />
              </Routes>
            )}
          </Suspense>
        </AppLayout>
      </>
    );
  } else {
    return (
      <>
        <Suspense fallback={<LoadingView />}>
          <Routes>
            <Route path={AppRoute.LOGIN} element={<Login />} />
            <Route path={AppRoute.SIGNIN_OIDC} element={<SigninOidc />} />
            <Route path={AppRoute.SILENT_SIGNIN_OIDC} element={<SilentSigninOidc />} />
            <Route path={AppRoute.SIGNOUT_CALLBACK_OIDC} element={<SignoutOidc />} />
            <Route path="*" element={<PreLogin />} />
          </Routes>
        </Suspense>
      </>
    );
  }
};
export default AppRoutes;
