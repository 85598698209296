import { FC } from "react";
import { Navigate } from "react-router-dom";
import Route from "route";

const PreLogin: FC = () => {
  const history = Route.history;
  return <Navigate replace={true} to="/login" state={{ redirectedFrom: history.location }} />;
};

PreLogin.displayName = "PreLogin";
export default PreLogin;
