import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { signinRedirectCallback } from "./userService";
import LoadingScreen from "common/components/pages/LoadingScreen";

const SigninOidc = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback();
      if (location?.search) {
        const params: any = queryString.parse(location.search);
        if (params.callback_args) {
          navigate(params.callback_args);
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }
    signinAsync();
  }, [navigate]);

  return <LoadingScreen showLogo={true} />;
};

export default SigninOidc;
