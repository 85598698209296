import React, { FC, useRef, useState } from "react";
import { Alert, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { AppState } from "store";
import { PasswordOptions } from "common/enums";
import { getPasswordOptionLabel } from "helper/HelperFunctions";
import { ChangePasswordConstants, ChangeSecurityConstants, ValidationContants } from "helper/constants";
import * as Validations from "helper/validations";
import { AppNotifier } from "../toaster/AppNotifier";
import Button, { ButtonType } from "../button/Button";
import ConfirmModal from "../ConfirmModal";
import { EyeIcon, EyeSlashIcon } from "assets/svg/SVGIconsCollection";
import { savePassword } from "store/services/password-service";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { PasswordModel } from "common/types/password";
import { useLogout } from "common/hooks/useLogout";

interface Visibility {
  current: boolean;
  new: boolean;
  confirm: boolean;
}
interface PasswordForm {
  current: string;
  new: string;
  confirm: string;
}
interface MyPasswordState {
  alert: string;
  message: string;
  showAlert: boolean;
  saving: boolean;
}

interface PasswordProps {
  onHide: () => void;
}

const Password: FC<PasswordProps> = (props) => {
  const ref = useRef<HTMLFormElement>(null);
  const [validated, setValidated] = useState<boolean>(false);
  const [toggleVisibility, setToggleVisibility] = useState<Visibility>({ current: false, new: false, confirm: false });
  const [passwordData, setPasswordData] = useState<PasswordForm>({ current: "", new: "", confirm: "" });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [state, setState] = useState<MyPasswordState>({
    alert: "",
    message: "",
    showAlert: false,
    saving: false
  });

  const passwordPolicySettings = useAppSelector((state: AppState) => state.passwordReducer.passwordPolicies);
  const { isLoading } = useAppSelector((state: AppState) => state.passwordReducer);
  const dispatch = useAppDispatch();
  const handleLogout = useLogout();

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = ref.current;
    if (form) {
      setValidated(false);
      submitChanges();
    } else {
      setValidated(true);
    }
  };

  const handleVisibility = (name: string) => {
    setToggleVisibility({
      ...toggleVisibility,
      [name]: !toggleVisibility[name as keyof Visibility]
    });
  };

  const handleOnChange: React.ChangeEventHandler = (e) => {
    const element = e.target as HTMLInputElement;
    const data = { ...passwordData, [element.id]: element.value };
    setPasswordData(data);
    setState({
      alert: "",
      message: "",
      showAlert: false,
      saving: false
    });
  };

  const passwordoptions = () => {
    const items: any[] = [];
    const selectedOptions = Object.keys(PasswordOptions)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => Number(key))
      .filter((option) => option > 0 && (option & passwordPolicySettings.passwordSettings.passwordOptions) === option);

    selectedOptions.forEach((option) => {
      const label = getPasswordOptionLabel(option);
      items.push(<li> {label}</li>);
    });

    return <div>{items}</div>;
  };

  const submitChanges = () => {
    if (!passwordData.current || passwordData.current.trim() === "") {
      setState({
        ...state,
        message: ValidationContants.OldPasswordWarning,
        alert: "warning",
        showAlert: true
      });
      return false;
    }

    if (!passwordData.new || passwordData.new.trim() === "") {
      setState({
        ...state,
        message: ValidationContants.NewPasswordWarning,
        alert: "warning",
        showAlert: true
      });
      return false;
    }

    if (!passwordData.confirm || passwordData.confirm.trim() === "") {
      setState({
        ...state,
        message: ValidationContants.ConfirmPasswordWarning,
        alert: "warning",
        showAlert: true
      });
      return false;
    }

    const validationResult = Validations.validatePasswordBasedOnPolicy(passwordData.new, passwordPolicySettings.passwordSettings);
    if (validationResult.isError) {
      setState({
        ...state,
        message: validationResult.errorDescription,
        alert: "warning",
        showAlert: true
      });
      return false;
    }

    if (passwordData.new.trim() === passwordData.confirm.trim()) {
      setShowConfirmationModal(true);
    } else {
      setState({
        ...state,
        message: ValidationContants.PasswordNotMatchedWarning,
        alert: "warning",
        showAlert: true
      });
      return false;
    }
  };

  const handlePasswordConfirmation = () => {
    setShowConfirmationModal(false);
    setState({
      alert: "",
      message: "",
      showAlert: false,
      saving: true
    });
    const passwordstate: PasswordModel = { password: passwordData.current, newPassword: passwordData.new };
    dispatch(
      savePassword(passwordstate, false, (response: any, error: any) => {
        setState({ ...state, saving: false });
        if (error) {
          AppNotifier.Error(error.response.data ?? ChangeSecurityConstants.SecurityUpdateFailed);
        } else {
          if (response) {
            AppNotifier.Success(ChangeSecurityConstants.SecurityUpdateSuccess);
            setState({
              ...state,
              alert: "",
              message: "",
              showAlert: false
            });
            setPasswordData({
              current: "",
              new: "",
              confirm: ""
            });
            props.onHide();
            handleLogout();
          } else {
            setState({
              ...state,
              message: response,
              alert: "warning",
              showAlert: true
            });
          }
        }
      })
    );
  };

  const hideModal: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onHide();
  };
  return (
    <div>
      <h3 className="heading-blue-1">{ChangePasswordConstants.Title}</h3>
      <p className="text-gray-description">{ChangePasswordConstants.Description}</p>
      <LoadingOverlay style={{ height: "100%" }} className="d-flex flex-column">
        <Loader loading={isLoading} />
        {state.showAlert && (
          <Alert variant={state.alert}>
            <i className="fas fa-exclamation-triangle" style={{ marginRight: "5px" }}></i>
            {state.message}
          </Alert>
        )}
        <Row>
          <Col>
            <Form ref={ref} id="change-password-form" noValidate validated={validated} className="me-3">
              <Form.Group controlId="current">
                <Form.Label>{ChangePasswordConstants.LabelCurrentPassword}</Form.Label>
                <InputGroup className="mb-3 input-with-toggle">
                  <Form.Control
                    size="sm"
                    type={toggleVisibility["current"] ? "text" : "password"}
                    onChange={handleOnChange}
                    placeholder={ChangePasswordConstants.PlaceholderCurrentPassword}
                  />
                  <InputGroup.Text onClick={() => handleVisibility("current")}>
                    {toggleVisibility["current"] ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="new">
                <Form.Label>{ChangePasswordConstants.LabelNewPassword}</Form.Label>
                <InputGroup className="mb-3 input-with-toggle">
                  <Form.Control
                    size="sm"
                    type={toggleVisibility["new"] ? "text" : "password"}
                    onChange={handleOnChange}
                    placeholder={ChangePasswordConstants.PlaceholderNewPassword}
                  />
                  <InputGroup.Text onClick={() => handleVisibility("new")}>
                    {toggleVisibility["new"] ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="confirm">
                <Form.Label>{ChangePasswordConstants.LabelConfirmPassword}</Form.Label>
                <InputGroup className="mb-3 input-with-toggle">
                  <Form.Control
                    size="sm"
                    type={toggleVisibility["confirm"] ? "text" : "password"}
                    onChange={handleOnChange}
                    placeholder={ChangePasswordConstants.PlaceholderConfirmPassword}
                  />
                  <InputGroup.Text onClick={() => handleVisibility("confirm")}>
                    {toggleVisibility["confirm"] ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
          {passwordPolicySettings && (
            <Col className="password-policies" xs={5}>
              <div className="mx-3 mb-2">
                <b>
                  <u>{ChangePasswordConstants.PasswordPolicyTitle}</u>
                </b>
              </div>
              <ul>
                <li className="margin-bottom-10-px">
                  {ChangePasswordConstants.PasswordLength}
                  {passwordPolicySettings.passwordSettings.length}
                </li>
                {passwordPolicySettings.passwordSettings.passwordOptions != PasswordOptions.None && (
                  <li>
                    {ChangePasswordConstants.PasswordRequired}
                    <ul className="margin-top-10-px">{passwordoptions()}</ul>
                    {(passwordPolicySettings.passwordSettings.passwordOptions & PasswordOptions.SpecialCharacters) ===
                      PasswordOptions.SpecialCharacters && (
                      <OverlayTrigger overlay={<Tooltip>{ChangePasswordConstants.PasswordCharactersList}</Tooltip>}>
                        <div className="margin-top-20-px tooltip-target">{ChangePasswordConstants.PasswordSpecialCharacters}</div>
                      </OverlayTrigger>
                    )}
                  </li>
                )}
              </ul>
            </Col>
          )}
        </Row>
        <div className="mt-auto d-flex justify-content-end">
          <Button
            data-test-auto="71675d6a-a9ae-4e67-8d42-bf4a5f6b77de"
            buttonType={ButtonType.Light}
            className="me-2"
            onClick={hideModal}
          >
            {ChangePasswordConstants.CancelButton}
          </Button>
          <Button data-test-auto="ba779241-c37b-4c7e-af30-8b0bb67f0c57" buttonType={ButtonType.Primary} onClick={handleSubmit}>
            {ChangePasswordConstants.OkButton}
          </Button>
        </div>
        <ConfirmModal
          show={showConfirmationModal}
          title={ChangeSecurityConstants.SecurityChangeModalTitle}
          confirmButtonName={ChangeSecurityConstants.ConfirmButtonName}
          onSubmit={handlePasswordConfirmation}
          onHide={() => {
            setShowConfirmationModal(false);
          }}
          className="modal-confirm-delete"
        >
          <span>{ChangeSecurityConstants.SecurityChangeLogoutWarning}</span>
        </ConfirmModal>
      </LoadingOverlay>
    </div>
  );
};

export default Password;
