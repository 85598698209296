import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout, { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { IHeaderProps } from "@sssuite-component-ui/ss-suite-layout/dist/Header/Header";
import { ISectionData } from "@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget";
import { IMenuSection, ISideMenuProps } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { Forethought, openWidgetOnClick } from "@sssuite-component-ui/forethought-ai-chatbot";

import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import AppRoute from "route";
import { getProductLeftMenuItems, getProductWidgetMenuItems, getSuiteUrl } from "store/services/layout-service";
import { SupportIcon } from "assets/svg/SVGIconsCollection";
import { getUserProfile, getUserRoles } from "store/services/user-profile-service";
import { UserProfile } from "common/types/userProfile";
import { HeaderConstants, WarningVisibleMinutes } from "helper/constants";
import LogoutModal from "../LogoutModal";
import { SessionTimeout, getCookieValue } from "@sssuite-component-ui/session-timeout";
import { signoutRedirect } from "oidc-client/userService";
import { AppState } from "store";
import { setUserLoggedOut } from "store/slices/auth-slice";
import { AuthState, UserPreviligeState } from "common/types/authentication";
import { getSessionIdleMinutes, resetUserCache } from "store/services/auth-service";
import { LogoutCause } from "common/enums";
import UserResourceChangeModal from "../UserResourceChangeModal";
import SignalRWebSocket from "../SignalRWebSocket";
import LastLoginModal from "../LastLoginModal";
import LoginHistoryModal from "../LoginHistoryModal";
import { getUserLastLoginSettings } from "store/services/login-history-service";
import MyProfileModal from "../my-profile/MyProfileModal";
import { getPasswordPolicySettings } from "store/services/password-service";
import { createForethoughtCookie } from "helper/HelperFunctions";
import { getIsProductEnabled, getIsProductSubscribed } from "store/services/post-auth-service";
import { getMfaOTPLength } from "store/services/otp-service";
import Notification from "../toaster/Notification";
import { getCompanyLogo, getCompanyWhiteLogo } from "store/services/branding-service";

interface AppLayoutProps {
  children: any;
}
declare global{
  interface Window{
    pendo: any;
  }
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const [sideBarData, setSideBarData] = useState<ISideMenuProps>();
  const [headerModel, setHeaderModel] = useState<any>();
  const [showModal, setShowModal] = useState<any>({
    myProfile: false,
    loginHistory: false,
    lastLogin: false,
    logout: false,
    userResourceChange: false
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const productLeftMenu: MenuModel = useAppSelector<MenuModel>((state: AppState) => state.layoutReducer);
  const { widgetMenu, suiteUrl }: WidgetMenuModel = useAppSelector((state: AppState) => state.layoutReducer?.widgetMenuData);

  const { user, loggedOut, sessionIdleMinutes, isResetCacheApiCalled } = useAppSelector<AuthState>(
    (appState: AppState) => appState.authReducer?.authentication
  );
  const { userPrivilegesChanged, logoutCause } = useAppSelector<UserPreviligeState>(
    (appState: AppState) => appState.authReducer?.userPrivilege
  );
  const userProfile: UserProfile = useAppSelector((state: AppState) => state.userProfileReducer?.userProfile);
  const { companyLogo } = useAppSelector((state: AppState) => state.brandingReducer);

  useEffect(() => {
    if (isResetCacheApiCalled) {
      dispatch(getSuiteUrl());
      dispatch(getProductLeftMenuItems());
      dispatch(getProductWidgetMenuItems());
      dispatch(getCompanyLogo());
      dispatch(getCompanyWhiteLogo());
      dispatch(getUserProfile());
      dispatch(getMfaOTPLength());
      dispatch(getUserRoles(user?.profile?.user_id || 0));
      dispatch(getIsProductEnabled());
      dispatch(getIsProductSubscribed());
      dispatch(getSessionIdleMinutes());
      dispatch(getPasswordPolicySettings());
    }
    if (!isResetCacheApiCalled && getCookieValue("userAutoLogout") !== "1") {
      dispatch(resetUserCache());
    }
  }, [isResetCacheApiCalled]);

  useEffect(() => {
    getDataForSideMenu(productLeftMenu?.leftMenuData, false);
    redirect(productLeftMenu?.routeList);
  }, [productLeftMenu]);

  useEffect(() => {
    setHeaderModel(
      headerConfig(
        user?.profile?.company_name,
        userProfile?.firstName,
        widgetMenu,
        suiteUrl,
        companyLogo.logoPath,
        companyLogo.isSsrLogo
      )
    );
  }, [widgetMenu, userProfile, user, suiteUrl, companyLogo]);

  useEffect(() => {
    setAutoLogout();
  }, [userPrivilegesChanged]);

  useEffect(() => {
    if(user.profile){
      injectPendoScript();
    }

    dispatch(
      getUserLastLoginSettings((result: boolean) => result && setShowModal((state: any) => ({ ...state, lastLogin: true })))
    );
  }, [user]);



  const setAutoLogout = () => {
    if (logoutCause != LogoutCause.None && userPrivilegesChanged && !showModal.userResourceChange)
      setShowModal((state: any) => ({ ...state, userResourceChange: true }));
  };

  const handleResetCache = (callback?: () => void): void => {
    dispatch(resetUserCache(callback));
  };

  const getDataForSideMenu = (menuSections: IMenuSection[], hideLeftMenu: boolean) => {
    const sideMenuProps: ISideMenuProps = {
      hasButton: false,
      topMenuData: [],
      bottomMenuData: [],
      onNavigate: (route: string) => {
        navigate(route);
      },
      hideSideMenu: hideLeftMenu,
      buttonIcon: "",
      buttonText: "",
      onButtonClick: () => {}
    };

    if (menuSections && menuSections.length) {
      let topMenuData = menuSections.filter((x) => x.items.some((y) => !y.isAtBottomOfLeftMenu));
      sideMenuProps.hasButton = false;
      sideMenuProps.bottomMenuData = menuSections.filter((x) => x.items.some((y) => y.isAtBottomOfLeftMenu));
      sideMenuProps.topMenuData = topMenuData.filter(
        (section) => !(section.items.length === 1 && section.items[0].caption === "Settings" && section.items[0].disabled)
      );
    }
    setSideBarData(sideMenuProps);
  };

  const redirect = (routes: TtcRoute[]) => {
    const path = AppRoute.history.location.pathname;
    if (path === "/") {
      const foundRoute = routes?.find((r) => r.isEnabled);
      foundRoute && navigate(foundRoute.route);
    }
  };

  const getSupportIcon = () => {
    return (
      <div className="header-actions-wrapper">
        <span onClick={openWidgetOnClick} title="Support">
          <SupportIcon />
        </span>
      </div>
    );
  };

  const getProfileDropdownItems = () => {
    return [
      {
        caption: HeaderConstants.Profile,
        onClick: () => setShowModal((state: any) => ({ ...state, myProfile: true }))
      },
      {
        caption: HeaderConstants.LoginHistory,
        onClick: () => setShowModal((state: any) => ({ ...state, loginHistory: true }))
      }
    ];
  };

  const headerConfig = (
    companyName: string,
    firstName: string,
    widgetMenus: ISectionData[],
    suiteUrl: string,
    logoPath: string,
    isSsrLogo: boolean
  ): IHeaderProps => {
    let headerprops: IHeaderProps = {
      productName: ProductType.TicTieCalculate,
      companyName: isSsrLogo ? companyName : "",
      companyLogo: !isSsrLogo && logoPath ? logoPath : "",
      onClickProductLogo: () => {
        AppRoute.redirect("/");
      },
      children: getSupportIcon(),
      profileDropDown: {
        dropdownHeader: `Hi, ${firstName?.trim()}!`,
        dropdownHeaderTitle: `${firstName?.trim()}`,
        onClickLogout: () => setShowModal((state: any) => ({ ...state, logout: true })),
        profileDropDownItems: getProfileDropdownItems()
      },
      widgetData: {
        onBackClick: () => {
          window.open(suiteUrl, "_blank");
        },
        dropDownData: widgetMenus
      }
    };
    return headerprops;
  };

  const injectPendoScript = () => {
    const {
      company_id,
      company_name,
      user_id,
      given_name: firstName,
      family_name: lastName,
      email,
    } = user?.profile || {};

    if (!company_id || !company_name || !user_id || !firstName || !lastName || !email) {
      console.log("User profile not available yet");
      return;
    }

    const apiKey = process.env.REACT_APP_PENDO_API_KEY;

    (function (p: any, e: any, n: any, d: any, o: any) {
      var v: any, w: any, x: any, y: any, z: any;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo","");

    window.pendo?.initialize({
      visitor: {
        id: `${company_id}-${user_id}-${email}`,
        email: email,
        firstName: firstName,
        lastName: lastName
      },
      account: {
        id: company_id,
        accountName: company_name,
        payingStatus: "trial"
      }
    });
  };

  return (
    <>
      <SessionTimeout
        deviceId={user?.profile?.device_id}
        logout={signoutRedirect}
        sessionIdleMinutes={sessionIdleMinutes}
        warningVisibleMinutes={WarningVisibleMinutes}
        currentTabLoggedOutShow={loggedOut}
        setCurrentTabLoggedOutShow={() => {
          dispatch(setUserLoggedOut(true));
        }}
        onExternalLogout={() => createForethoughtCookie("isForethoughtWidgetVisible", "false")}
      />
      {headerModel && isResetCacheApiCalled ? (
        <>
          <Notification />
          <Layout headerProps={headerModel} sideMenuProps={sideBarData}>
            {children}
          </Layout>

          {showModal.userResourceChange && (
            <UserResourceChangeModal
              show={showModal.userResourceChange}
              logoutCause={logoutCause}
              onHide={() => setShowModal((state: any) => ({ ...state, userResourceChange: false }))}
              onResetCache={handleResetCache}
            />
          )}
          {showModal.lastLogin && (
            <LastLoginModal
              show={showModal.lastLogin}
              onHide={() => setShowModal((state: any) => ({ ...state, lastLogin: false }))}
            />
          )}
          {showModal.loginHistory && (
            <LoginHistoryModal
              show={showModal.loginHistory}
              onHide={() => setShowModal((state: any) => ({ ...state, loginHistory: false }))}
            />
          )}
          {showModal.myProfile && (
            <MyProfileModal
              show={showModal.myProfile}
              onHide={() => setShowModal((state: any) => ({ ...state, myProfile: false }))}
            />
          )}
          <Forethought offsetY="40px" positionX="right" positionY="bottom" hideWidgetIframeOnLoad="true" />
        </>
      ) : (
        <></>
      )}
      <LogoutModal show={showModal.logout} onHide={() => setShowModal((state: any) => ({ ...state, logout: false }))} />
      {user?.profile?.company_id > 0 && userProfile.userId > 0 && <SignalRWebSocket companyId={user?.profile?.company_id} />}
    </>
  );
};
export default AppLayout;
