import { Action, ThunkAction } from "@reduxjs/toolkit";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setIsLoading, setProductEnabled, setProductSubscribed } from "store/slices/post-auth-slice";

export const getIsProductEnabled = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    Axios.get(`${API.COMPANY.PRODUCT.IS_ENABLED}`, {
      method: "GET",
      credentials: "include"
    }).then((data) => {
      dispatch(setProductEnabled(data));
    });
  } catch (error: any) {
    // handle error
    dispatch(setIsLoading(false));
  }
};

export const getIsProductSubscribed = (): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    Axios.get(`${API.COMPANY.PRODUCT.IS_SUBSCRIBED}`, {
      method: "GET",
      credentials: "include"
    }).then((data) => {
      dispatch(setProductSubscribed(data));
    });
  } catch (error: any) {
    // handle error
    dispatch(setIsLoading(false));
  }
};
