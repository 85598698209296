import React, { FC } from "react";
import Profile from "./Profile";
import Password from "./Password";

interface UserSettingsContentProps {
  eventKey: string;
  onHide: () => void;
}
const MyAccountContent: FC<UserSettingsContentProps> = ({ eventKey, onHide }) => {
  let content;
  switch (eventKey) {
    case "profile":
      content = <Profile onHide={onHide} />;
      break;
    case "password":
      content = <Password onHide={onHide} />;
      break;
    default:
      content = <></>;
  }
  return content;
};

export default MyAccountContent;
