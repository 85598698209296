import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialBookmarkTemplateState: BookmarkTemplateState = {
  isLoading: false,
  data: []
};

const bookmarkTemplateSlice = createSlice({
  name: "bookmarkTemplate",
  initialState: initialBookmarkTemplateState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setBookmarkTemplateData: (state, action: PayloadAction<BookmarkTemplateModel[]>) => {
      state.data = action.payload;
      state.isLoading = false;
    }
  }
});

export const { setIsLoading, setBookmarkTemplateData } = bookmarkTemplateSlice.actions;
export default bookmarkTemplateSlice.reducer;
