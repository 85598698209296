import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signoutRedirectCallback } from "oidc-client/userService";
import LoadingView from "common/components/pages/LoadingScreen";

function SignoutOidc() {
  const navigate = useNavigate();
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback();
      navigate("/");
    }
    signoutAsync();
  }, [navigate]);

  return <LoadingView showLogo={true} />;
}

export default SignoutOidc;
