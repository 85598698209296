import { createBrowserHistory } from "history";
import * as RoutePaths from "./paths";

const history = createBrowserHistory();

const redirect = (path: string, state?: any) => {
  history.push(path, state);
};

const routes = {
  redirect,
  history,
  DEFAULT: RoutePaths.DEFAULT,
  BOOKMARK_TEMPLATE: RoutePaths.BOOKMARK_TEMPLATE,
  SETTINGS: RoutePaths.SETTINGS,
  FEATURE_DISABLED: RoutePaths.FEATURE_DISABLED,
  LOGIN: RoutePaths.LOGIN,
  SIGNIN_OIDC: RoutePaths.SIGNIN_OIDC,
  SILENT_SIGNIN_OIDC: RoutePaths.SILENT_SIGNIN_OIDC,
  SIGNOUT_CALLBACK_OIDC: RoutePaths.SIGNOUT_CALLBACK_OIDC
};

export default routes;
