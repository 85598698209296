import React, { FC, useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { DateFormatConstants, LoginHistoryConstants } from "helper/constants";
import { AppState } from "store";
import { getUserLoginHistory, updateUserLastLoginEnable } from "store/services/login-history-service";
import { getFormattedLocalDateTime } from "helper/dateHelperFunctions";
import Modal from "./modal/Modal";
import Button, { ButtonType } from "./button/Button";
import { getBrowserDetails } from "helper/HelperFunctions";
import { AppNotifier } from "./toaster/AppNotifier";

interface LastLoginProps {
  show: boolean;
  onHide: () => void;
}

const LastLoginModal: FC<LastLoginProps> = ({ show, onHide }) => {
  const [lastLogin, setLastLogin] = useState<UserLoginHistory>();
  const { isLoading, loginHistory, deviceId, lastLoginPrompt } = useAppSelector((state: AppState) => state.loginHistoryReducer);
  const [enablePrompt, setEnablePrompt] = useState(lastLoginPrompt);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserLoginHistory(1, LoginHistoryConstants.CountPerPage));
  }, []);

  useEffect(() => {
    setEnablePrompt(lastLoginPrompt);
  }, [lastLoginPrompt]);

  useEffect(() => {
    if (loginHistory.length > 1) {
      loginHistory[0].deviceId === deviceId ? setLastLogin(loginHistory[1]) : setLastLogin(loginHistory[0]);
    }
  }, [loginHistory]);

  const handleCancel = () => {
    onHide();
  };

  const handleToggleChange = (enable: boolean) => {
    dispatch(updateUserLastLoginEnable(enable, () => AppNotifier.Success(LoginHistoryConstants.LastLoginPromptSuccess)));
    setEnablePrompt(enable);
  };

  const modalFooter: React.ReactNode = (
    <div className="d-flex justify-content-between align-items-center">
      <Form.Check
        onChange={() => handleToggleChange(!enablePrompt)}
        checked={!lastLoginPrompt}
        type="checkbox"
        id="last_login"
        label={LoginHistoryConstants.LastLoginPromptText}
      />
      <Button buttonType={ButtonType.Primary} onClick={handleCancel}>
        Close
      </Button>
    </div>
  );

  return (
    <Modal
      title={LoginHistoryConstants.LastLoginModalTitle}
      isOpen={show && lastLogin && lastLoginPrompt}
      onClose={handleCancel}
      onHide={handleCancel}
      dialogClassName="last-login"
      footer={modalFooter}
      footerClassName="d-block"
    >
      <LoadingOverlay>
        <Row className="mb-3">
          <Col sm={3}>
            <b>Log In On</b>
          </Col>
          <Col>{getFormattedLocalDateTime(lastLogin?.loggedInOn ?? "", DateFormatConstants.LastLoginDateFormat)}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={3}>
            <b>IP Address</b>
          </Col>
          <Col>{lastLogin?.ipAddress}</Col>
        </Row>
        <Row>
          <Col sm={3}>
            <b>Browser</b>
          </Col>
          <Col>{lastLogin && getBrowserDetails(lastLogin.clientApp)}</Col>
        </Row>
        <Loader classNamePrefix="sm" text="" loading={isLoading} />
      </LoadingOverlay>
    </Modal>
  );
};

export default LastLoginModal;
