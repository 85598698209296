import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const leftMenuInitialState: MenuModel = {
  isLoading: true,
  leftMenuData: [],
  hideLeftmenu: false,
  routeList: [],
  isRoutesEmpty: false,
  isAllRoutesDisabled: false
};

const widgetMenuInitialState: WidgetMenuModel = {
  isLoading: false,
  widgetMenu: [],
  suiteUrl: ""
};

const initialLayoutState: any = {
  leftMenuData: leftMenuInitialState,
  widgetMenuData: widgetMenuInitialState
};

const layoutSlice = createSlice({
  name: "layout",
  initialState: initialLayoutState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setLeftMenuData(state, action: PayloadAction<LeftMenuModel>) {
      state.leftMenuData = action.payload.leftMenuData;
      state.isRoutesEmpty = action.payload.leftMenuData?.length == 0;
      state.routeList = action.payload.routeList;
      state.isAllRoutesDisabled = action.payload.routeList?.every((r) => !r.isEnabled);
      state.isLoading = false;
    },
    setWidgetMenuData(state, action: PayloadAction<WidgetMenuModel>) {
      state.widgetMenuData.widgetMenu = action.payload.widgetMenu;
      state.isLoadgin = false;
    },
    setSuiteUrl(state, action: PayloadAction<string>) {
      state.widgetMenuData.suiteUrl = action.payload;
    }
  }
});

export const { setIsLoading, setLeftMenuData, setWidgetMenuData, setSuiteUrl } = layoutSlice.actions;

export default layoutSlice.reducer;
