import React, { FC } from "react";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 

import { NotifierTypes } from "common/enums";
import { CheckCircle, WarningIcon } from "assets/svg/SVGIconsCollection";
import { themeColors } from "styles/colorConstants";
import "./Toaster.scss";

export class AppNotifier extends React.Component<any, any> {
  static Error = (message: string) =>
    message &&
    toast.error(<ToastComponent message={message} type={NotifierTypes.Error} />, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toaster-body toaster-error"
    } as ToastOptions);

  static Warning = (message: string) =>
    message &&
    toast.warn(<ToastComponent message={message} type={NotifierTypes.Warning} />, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toaster-body toaster-warning"
    } as ToastOptions);

  static Success = (message: string) =>
    message &&
    toast.success(<ToastComponent message={message} type={NotifierTypes.Success} />, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toaster-body toaster-success"
    } as ToastOptions);

  static Info = (message: string) =>
    message &&
    toast.info(<ToastComponent message={message} type={NotifierTypes.Info} />, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toaster-body toaster-info"
    } as ToastOptions);
}

export const ToastComponent: FC<{ message: string; type: NotifierTypes }> = ({ message, type }) => {
  const getNotificationIcon = (notificationType: NotifierTypes) => {
    switch (notificationType) {
      case NotifierTypes.Error:
        return <WarningIcon color={themeColors["$error-600"]} />;
      case NotifierTypes.Success:
        return <CheckCircle color={themeColors["$success-500"]} />;
      case NotifierTypes.Info:
        return <CheckCircle color={themeColors["$Theme-Sapphire"]} />;
      case NotifierTypes.Warning:
        return <CheckCircle color={themeColors["$theme-warning"]} />;
      default:
        return <WarningIcon color={themeColors["$error-600"]} />;
    }
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <div className="toaster-icon">{getNotificationIcon(type)}</div>
      <div className="toaster-text">{message}</div>
    </div>
  );
};

export const Toaster: FC = () => {
  return (
    <div>
      <ToastContainer
        autoClose={5000}
        className="toaster-container"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnHover
        style={{ fontSize: "14px" }}
      />
    </div>
  );
};
