import React from "react";
import { Modal as TtcModal, ModalProps } from "react-bootstrap";
import "./Modal.scss";

interface TtcModalProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  fullscreen?: true | string | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down";
  children?: any;
  footer?: any;
  footerClassName?: string;
  className?: string;
}

const Modal: React.FC<TtcModalProps> = ({
  isOpen,
  onClose,
  title,
  fullscreen,
  children,
  footer,
  className,
  footerClassName,
  ...props
}) => {
  return (
    <TtcModal {...props} fullscreen={fullscreen} show={isOpen} onHide={onClose} className={`ss-modal ${className}`}>
      <TtcModal.Header closeButton>{title ? <TtcModal.Title>{title}</TtcModal.Title> : ""} </TtcModal.Header>
      <TtcModal.Body>{children}</TtcModal.Body>
      {footer && <TtcModal.Footer className={footerClassName}>{footer}</TtcModal.Footer>}
    </TtcModal>
  );
};
export default Modal;
