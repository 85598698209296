import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";

import AuthProvider from "oidc-client/AuthProvider";
import reportWebVitals from "./reportWebVitals";
import { store } from "store";

/* import only the necessary Bootstrap files */
import "bootstrap/scss/bootstrap.scss";
import "react-overlay-loader/styles.css";
import "./index.scss";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider store={store}>
        <App />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
