import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth-slice";
import layoutReducer from "./slices/layout-slice";
import bookmarkTemplateReducer from "./slices/bookmark-template-slice";
import userProfileReducer from "./slices/user-profile-slice";
import bookmarkTreeReducer from "./slices/bookmark-tree-slice";
import postAuthReducer from "./slices/post-auth-slice";
import loginHistoryReducer from "./slices/login-history-slice";
import otpReducer from "./slices/otp-slice";
import passwordReducer from "./slices/password-slice";
import companySettingsReducer from "./slices/company-settings-slice";
import notificationReducer from "./slices/notification-slice";
import brandingReducer from "./slices/branding-slice";

const reducer: any = combineReducers({
  authReducer,
  layoutReducer,
  bookmarkTemplateReducer,
  userProfileReducer,
  bookmarkTreeReducer,
  postAuthReducer,
  loginHistoryReducer,
  otpReducer,
  passwordReducer,
  companySettingsReducer,
  notificationReducer,
  brandingReducer
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production"
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
