import { Action, ThunkAction } from "@reduxjs/toolkit";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setIsLoading, setLastLoginPrompt, setLoginHistory } from "store/slices/login-history-slice";

export const getUserLoginHistory =
  (pageNumber: number = 1, pageSize: number = 15): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      Axios.get(`${API.SUITE_LOGIN_HISTORY.GET_LOGIN_HISTORY}/${pageNumber}/${pageSize}`, {
        method: "GET",
        credentials: "include"
      }).then((response: any) => {
        dispatch(setLoginHistory({ count: response.loginHistories?.count, loginHistory: response.loginHistories?.items || [] }));
      });
    } catch (error: any) {
      dispatch(setIsLoading(false));
    }
  };

export const getUserLastLoginSettings =
  (callback?: (result: boolean) => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      Axios.get(`${API.SUITE_USER_MANAGEMENT.LAST_LOGIN_USER_SETTINGS}`, {
        method: "GET",
        credentials: "include"
      }).then((response: any) => {
        let data;
        if (typeof response === "boolean") {
          data = response;
        } else if (typeof response === "object") {
          data = response.data;
        }
        dispatch(setLastLoginPrompt(data));
        dispatch(setIsLoading(false));
        callback && callback(data);
      });
    } catch (error: any) {
      dispatch(setIsLoading(false));
    }
  };

export const updateUserLastLoginEnable =
  (payload: boolean, callback: () => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      Axios.put(`${API.SUITE_USER_MANAGEMENT.LAST_LOGIN_USER_SETTINGS}/${payload}`, {
        method: "GET",
        credentials: "include"
      }).then((data: any) => {
        dispatch(setLastLoginPrompt(payload));
        dispatch(setIsLoading(false));
        callback && callback();
      });
    } catch (error: any) {
      dispatch(setIsLoading(false));
    }
  };
