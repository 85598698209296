import { NotifierTypes } from "common/enums";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AppState } from "store";
import { setNotification } from "store/slices/notification-slice";
import { AppNotifier } from "./AppNotifier";

const Notification: FC = () => {
  const [currentPath, setCurrentPath] = useState("");
  const { message, type, identifier } = useAppSelector((state: AppState) => state.notificationReducer);
  const location = useLocation();
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (currentPath !== location.pathname) {
      toast.dismiss();
      dispatch(setNotification({ message: "", type: NotifierTypes.None }));
      setCurrentPath(location.pathname);
      return;
    }

    if (type === NotifierTypes.Success) AppNotifier.Success(message);
    else if (type === NotifierTypes.Error) AppNotifier.Error(message);
    else if (type === NotifierTypes.Warning) AppNotifier.Warning(message);
  }, [location, currentPath, identifier.toString()]);

  return <></>;
};

export default Notification;
