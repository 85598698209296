import { CompanySettings } from "common/types/companySettings";
export const WarningVisibleMinutes = 1;

export const HeaderConstants = {
  Profile: "Profile",
  LoginHistory: "Login History"
};

export const UserManagementConstants = {
  UserLogoutWarnigMessage: "Are you sure you want to log out? You will be logged out of all instances of the SafeSend Suite.",
  LogoutButtonText: "Yes, Log Out",
  CancelButtonText: "No, Discard",
  LogoutTitle: "Log Out"
};

export const UserAutoLogoutModalConstants = {
  HeaderTitle: "Permission Changed",
  Message: "Your SafeSend permissions have been changed. You must login again for the changes to take effect.",
  SubmitButtonText: "Login"
};

export const LoginHistoryConstants = {
  CountPerPage: 15,
  LastLoginModalTitle: "Last Login",
  LastLoginPromptText: "Don't show this again",
  LoginHistoryModalTitle: "Login History",
  PromptText: "Enable last login prompt",
  LastLoginPromptSuccess: "Your settings have been updated."
};

export const DateFormatConstants = {
  DefaultDateFormat: "MM/DD/YYYY",
  ReportsDateFormat: "MM/DD/YYYY hh:mm:ss a",
  LoginTableDateFormat: "MM/DD/YYYY hh:mm:ss a",
  LastLoginDateFormat: "MM/DD/YYYY hh:mm:ss A",
  SameDayTimeFormat: "HH:mm",
  CurrentWeekDateFormat: "ddd MM:DD",
  MessageDetailDateFormat: "ddd MM-DD-YYYY HH:mm",
  DefaultTimeFormat: "HH:mm:ss A"
};

export const MyAccountConstants = {
  ModalTitle: "My Account",
  APIResponse: {
    UserUpdateSuccess: "User updated successfully."
  },
  UserUpdateFailedMessage: "Updating user details failed, please re-login.",
  SystemAdminDeletionErrorMessage: "Cannot save a Primary Admin without SystemAdmin Group."
};

export const OTPVerifyConstants = {
  InvalidOTP: "Invalid access code",
  validOTP: "Access code verified successfully"
};

export const MyAccountTitle: Record<string, string> = {
  profile: "Profile",
  password: "Password"
};

export const ValidationContants = {
  FirstNameWarning: "Please enter first name.",
  LastNameWarning: "Please enter last name.",
  NameLengthWarning: "Name must have up to 100 characters",
  EmailAdrressWarning: "Please enter email address.",
  ValidEmailAddressWarning: "Please enter valid  email address.",
  ValidIdentityServerEmailAddressWarning:
    "Only letters (a-z), numbers (0-9), symbol (@), dash (-), underscore (_), period (.) and apostrophe (') are allowed.",
  EmailNotMatching: "Email and Confirm Email did not match",
  PhoneNumberWarning: "Please enter the phone number.",
  PhoneNumberLengthWarning: "Please enter a 10 digit phone number.",
  MobileNumberWarning: "Please enter the mobile number.",
  MobileNumberLengthWarning: "Please enter a 10 digit mobile number.",
  CountryCodeWarning: "Please select the country code.",
  PTINWarning: "PTIN should start with P followed by 8 digit number.",
  EnterPasswordWarning: "Please enter password and confirm password.",
  PasswordNotMatchedWarning: "Password and confirmed password did not match.",
  NewPasswordWarning: "Please enter new password.",
  OldPasswordWarning: "Please enter old password",
  ConfirmPasswordWarning: "Please enter confirm password",
  PasswordLengthWarning: "Password must be 8 characters.",
  PasswordWithoutSpaceWarning: "Please enter password without space.",
  ZipWarning: "Please enter zip.",
  ZipLengthWarning: "Please enter a 5 digit zip.",
  ExtensionWarning: "Please enter less than 7 digit extension number.",
  FaxWarning: "Please enter a 10 digit fax number.",
  StateWarning: "Please select state.",
  CityWarning: "Please enter city.",
  ValidCityWarning: "Please enter valid city name.",
  PdfFileValidation: "Please upload Pdf files only.",
  PaymentURLValidation: "Please enter a valid payment URL.",
  FirmKeyWarning: "Please enter valid firm key.",
  AzureGroupIdWarning: "Please enter valid Azure Group Id.",
  PasswordWarning: "Please enter password.",
  K1InstructionNotAVailable: "K1 instruction is not available in the uploaded file",
  NoContentAvailable: "No content available",
  EINWarning: "Please enter valid EIN.",
  FileFormatNotSupported: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
  FileSizeExceeded:
    "Sorry! The file(s) you are attaching exceed the allowable size limit (3GB). Please remove a few files and try again.",
  UpperCaseValidationError: "Password should contain at least one uppercase character.",
  LowerCaseValidationError: "Password should contain at least one lowercase character.",
  NumbersValidationError: "Password should contain at least one number.",
  SpecialCharactersValidationError: "Password should contain at least one special character.",
  CsvFileValidation: "Please upload Csv files only.",
  SameEmailValidation: "Email Already selected.",
  NotSelectedAnyMessageWarning: "Please select any message.",
  EmptyInputFieldWarning: "This field can't be empty",
  DateInvalidWarning: "Please enter valid date"
};

export const ProfileInformation = {
  Title: "Profile Information",
  Description: "View and manage your profile information here.",
  LabelTitle: "Title",
  LabelEmail: "Email Address",
  LabelFirstName: "First Name",
  LabelLastName: "Last Name",
  LabelPhoneNumber: "Phone Number",
  LabelExtension: "Extension",
  LabelFaxNumber: "Fax Number",
  LabelPTIN: "PTIN",
  LabelMobileNumber: "Mobile Number",
  PlaceholderTitle: "Enter Title",
  PlaceholderEmail: "Enter Email Address",
  PlaceholderFirstName: "Enter First Name",
  PlaceholderLastName: "Enter Last Name",
  PlaceholderPhoneNumber: "Enter Phone Number",
  PlaceholderExtension: "Enter Phone Extension",
  PlaceholderFaxNumber: "Enter Fax Number",
  PlaceholderPTIN: "Enter PTIN",
  PlaceholderMobileNumber: "Enter Mobile Number",
  VerifyTitle: "Verify Number",
  VerifyHelpText: "You can skip the verification step. On next login, you will be prompted to verify the mobile number",
  PlaceholderAccessCode: "Access code",
  AccessCodeButton: "Verify Code",
  OkButton: "Save",
  CancelButton: "Cancel",
  FaxLength: 10,
  PTINLength: 9,
  PhoneNumberLength: 10,
  ExtensionLength: 7,
  NameLength: 100
};

export const ChangePasswordConstants = {
  Title: "Change Password",
  Description: "Update your password for SafeSend login here.",
  LabelCurrentPassword: "Current Password",
  LabelNewPassword: "New Password",
  LabelConfirmPassword: "Confirm Password",
  OkButton: "Update Password",
  CancelButton: "Cancel",
  PasswordPolicyTitle: "Your password must have:",
  PasswordLength: "Minimum Number of characters: ",
  PasswordRequired: "Required character types: Your passsword must include one of each of the following character types:",
  PasswordSpecialCharacters: "(View list of available special characters)",
  PasswordCharactersList: "~ ! @ # $ % ^ & * _",
  PlaceholderCurrentPassword: "Enter Current Password",
  PlaceholderNewPassword: "Enter New Password",
  PlaceholderConfirmPassword: "Repeat New Password"
};

export const ChangeSecurityConstants = {
  SecurityChangeModalTitle: "Change Password",
  ConfirmButtonName: "Confirm",
  SecurityChangeLogoutWarning: "Changing your Password will require you to login again. Make the change?",
  SecurityUpdateSuccess: "Password updated successfully.",
  SecurityUpdateFailed: "Updating password failed, please re-login"
};

export const FeatureDisabledConstants = {
  DisabledTitle: "Feature Disabled",
  DeniedTitle: "Access Denied",
  DisabledByTierDescription: "This product is not supported on your current subscription tier," + "\n" + "please contact your ",
  DisabledByTierCustomerSuccessRepresentative: "Customer Success Representative ",
  DisabledByTierEnding: "for access.",
  ProductDisabledInSettingsDescription:
    "We are sorry, but this feature is currently not available for your use." +
    "\n" +
    "Please contact your System Administrator for more information.",
  NoPermissionDescription: "We are sorry, you do not have permission to access ",
  ProductName: "Safesend TicTie Calculate.",
  NoPermissionContactAdministrator: "\n" + "Please contact your system administrator to update your user profile."
};

export const AccessDeniedConstants = {
  AccessDeniedTitle: "Access Denied",
  AccessDeniedDescription: "Please consult your system" + "\n" + "administrator for assistance.",
  AccessDeniedButtonText: "Sign in as Different User"
};

export const CompanySettingsConstants = {
  SettingsUpdatedSuccessMessage: "Settings updated successfully."
};

export const BookmarkTemplateValidations = {
  MaxCharacterLength: 150,
  BookmarkNameRequiredMessage: "Template name is required.",
  BookmarkNameMaxLengthMessage: "Template name should be less than 150 characters."
};
