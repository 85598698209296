import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { LogoutCause } from "common/enums";
import { store } from "store";
import { setUserPrivilege } from "store/slices/auth-slice";

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    "Content-Type": "application/json"
  }
};

// Axios Interceptor to intercept all the API requests
export class AxiosService {
  axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create(defaultOptions);
    this.setUpInterceptors();
  }

  setUpInterceptors() {
    // Request interceptor
    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const appState: any = store.getState();
        if (appState?.authReducer?.authentication?.user?.access_token) {
          if (config.headers) {
            config.headers.Authorization = `Bearer ${appState.authReducer.authentication.user.access_token}`;
            // you can update more header properties here
          }
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    // Response interceptor
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError | any) => {
        const errCode = error?.response?.status;
        if (errCode) {
          switch (errCode) {
            case 400:
              // handle client-side error
              return Promise.reject(error);
            case 401:
              // handle client-side error
              return Promise.reject(error);
            case 404:
              // handle client-side error
              return Promise.reject(error);
            case 419:
              // handle client-side error
              store.dispatch(
                setUserPrivilege({
                  logoutCause: error.response?.data?.logoutCause || LogoutCause.None,
                  userPrivilegesChanged: true
                })
              );
              break; // Add break statement here
            case 500:
              // handle server-side error
              return Promise.reject(error);
            case 504:
              // handle server-side error
              return Promise.reject(error);
            case 512:
              // handle server-side error
              if (window.location.pathname !== "/restricted-access") {
                error.response && window.location.replace("/restricted-access");
              }
              break;
            default:
              if (window.location.href.includes("restricted-access")) {
                //
              }
              return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  get = (url: string, headers?: any) => {
    return this.axiosInstance
      .get(url, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));
  };

  post = (url: string, data: any, headers?: any) => {
    return this.axiosInstance
      .post(url, data, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));
  };

  delete = (url: string, headers: any = {}) =>
    this.axiosInstance
      .delete(url, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));

  deleteWithPayload = (url: string, data?: any, headers?: any) =>
    this.axiosInstance
      .delete(url, {
        data,
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));

  put = (url: string, data?: any, headers?: any) =>
    this.axiosInstance
      .put(url, data, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));

  patch = (url: string, data?: any, headers?: any) => {
    return this.axiosInstance
      .patch(url, data, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));
  };
}

const Axios = new AxiosService();
export default Axios;
