import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyLogo: {
    isLoading: false,
    isSsrLogo: false,
    logoPath: ""
  },
  companyWhiteLogo: {
    isLoading: false,
    whiteLogoPath: ""
  }
};

const brandingSlice = createSlice({
  name: "branding",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.companyLogo.isLoading = action.payload;
    },
    setIsWhitleLogoLoading: (state, action) => {
      state.companyWhiteLogo.isLoading = action.payload;
    },
    setCompanyLogo: (state, action) => {
      state.companyLogo.isSsrLogo = action.payload.isSsrlogo;
      state.companyLogo.logoPath = action.payload.logoPath;
      state.companyLogo.isLoading = false;
    },
    setCompanyWhiteLogo: (state, action) => {
      state.companyWhiteLogo.whiteLogoPath = action.payload;
      state.companyWhiteLogo.isLoading = false;
    }
  }
});

export const { setIsLoading, setIsWhitleLogoLoading, setCompanyLogo, setCompanyWhiteLogo } = brandingSlice.actions;
export default brandingSlice.reducer;
