import { userPrivilegeChanged } from "./../services/auth-service";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LogoutCause } from "common/enums";
import { AuthState, UserPreviligeState } from "common/types/authentication";

const authenticationInitialState: AuthState = {
  user: null,
  isLoadingUser: false,
  sessionIdleMinutes: 0,
  loggedOut: false,
  isResetCacheApiCalled: false
};

const userPrivilegeInitialState: UserPreviligeState = {
  logoutCause: LogoutCause.None,
  userPrivilegesChanged: false
};

const initialAuthState: any = {
  authentication: authenticationInitialState,
  userPrivilege: userPrivilegeInitialState
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setAuthentication(state, action: PayloadAction<any>) {
      state.authentication = { user: action.payload, isLoadingUser: false };
    },
    setUserPrivilege(state, action: PayloadAction<UserPreviligeState>) {
      state.userPrivilege = action.payload;
    },
    setUserLoggedOut(state, action: PayloadAction<boolean>) {
      state.authentication.loggedOut = action.payload;
    },
    setSessionIdleMinutes(state, action: PayloadAction<number>) {
      state.authentication.sessionIdleMinutes = action.payload;
    },
    setResetCacheApiCalled(state) {
      state.authentication.isResetCacheApiCalled = true;
    }
  }
});

export const { setAuthentication, setUserPrivilege, setUserLoggedOut, setSessionIdleMinutes, setResetCacheApiCalled } = authSlice.actions;

export default authSlice.reducer;
