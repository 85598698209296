import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthenticationProvider } from "common/enums";
import { UserProfile, UserProfileData } from "common/types/userProfile";

const intialUserProfile: UserProfile = {
  userId: 0,
  firstName: "",
  lastName: "",
  emailAddress: "",
  phone: "",
  extension: "",
  fax: "",
  title: "",
  countryCode: "",
  mobileNumber: "",
  isMobileVerify: false,
  ptin: "",
  createdOn: "",
  isDeleted: 0,
  metadata: "",
  authenticationProviders: [AuthenticationProvider.AzureAD],
  deviceId: "",
  isMFAEnabled: false,
  modifiedBy: 0,
  readonlyFields: ["0"],
  revokeStatus: 0,
  userGroups: []
};

const intialUserProfileData: UserProfileData = {
  userProfile: intialUserProfile,
  isLoading: false,
  userRoles: []
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: intialUserProfileData,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setUserProfile(state, action: PayloadAction<UserProfile>) {
      state.userProfile = action.payload;
      state.isLoading = false;
    },
    setUserRoles(state, action: PayloadAction<string[]>) {
      state.userRoles = action.payload;
    }
  }
});

export const { setIsLoading, setUserProfile, setUserRoles } = userProfileSlice.actions;
export default userProfileSlice.reducer;
