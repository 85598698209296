import { useAppDispatch } from "common/hooks/redux-hooks";
import { LoginHistoryConstants } from "helper/constants";
import { FC, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { AppState } from "store";
import { updateUserLastLoginEnable } from "store/services/login-history-service";
import Modal from "./modal/Modal";
import LoginHistoryTable from "./LoginHistoryTable";
import { Form } from "react-bootstrap";
import Button, { ButtonType } from "./button/Button";
import { AppNotifier } from "./toaster/AppNotifier";

interface LoginHistoryModalProps {
  show: boolean;
  onHide: () => void;
}

const LoginHistoryModal: FC<LoginHistoryModalProps> = ({ show, onHide }) => {
  const { lastLoginPrompt } = useSelector((state: AppState) => state.loginHistoryReducer);
  const [enablePrompt, setEnablePrompt] = useState(lastLoginPrompt);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setEnablePrompt(lastLoginPrompt);
  }, [lastLoginPrompt]);

  const handleCancel = () => {
    onHide();
  };

  const handleToggleChange = (enable: boolean) => {
    dispatch(updateUserLastLoginEnable(enable, () => AppNotifier.Success(LoginHistoryConstants.LastLoginPromptSuccess)));
    setEnablePrompt(enable);
  };

  const modalFooter: React.ReactNode = (
    <div className="d-flex justify-content-between align-items-center">
      <Form.Check
        onChange={() => handleToggleChange(!enablePrompt)}
        checked={lastLoginPrompt}
        type="checkbox"
        id="last_login"
        label={LoginHistoryConstants.PromptText}
      />
      <Button buttonType={ButtonType.Primary} onClick={handleCancel}>
        Close
      </Button>
    </div>
  );

  return (
    <Modal
      title={LoginHistoryConstants.LoginHistoryModalTitle}
      isOpen={show}
      onClose={handleCancel}
      size="lg"
      className="login-history"
      footer={modalFooter}
      footerClassName="d-block"
    >
      <LoginHistoryTable />
    </Modal>
  );
};

export default LoginHistoryModal;
