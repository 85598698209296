import React from "react";

export const InfoCircle: React.FC<IconProps> = ({ title, className, width, height, disabled, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "16"}
    height={height || "16"}
    fill={color || "#565A5E"}
    className="bi bi-info-circle"
    viewBox="0 0 16 16"
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
  </svg>
);

export const EyeIcon: React.FC<IconProps> = ({ title, className, width, height, disabled, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "16"}
    height={height || "16"}
    fill={color || "#565A5E"}
    className="bi bi-eye"
    viewBox="0 0 16 16"
  >
    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
  </svg>
);

export const EyeSlashIcon: React.FC<IconProps> = ({ title, className, width, height, disabled, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "16"}
    height={height || "16"}
    fill={color || "#565A5E"}
    className="bi bi-eye-slash"
    viewBox="0 0 16 16"
  >
    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
  </svg>
);

export const SupportIcon: React.FC<IconProps> = ({ title, className, width, height, disabled, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width || "20"} height={height || "20"} fill={"none"} viewBox="0 0 12 15">
    <path
      fill={color || "#565A5E"}
      d="M6 1a5 5 0 00-5 5v1h1a1 1 0 011 1v3a1 1 0 01-1 1H1a1 1 0 01-1-1V6a6 6 0 1112 0v6a2.5 2.5 0 01-2.5 2.5H7.366a1 1 0 01-.866.5h-1a1 1 0 010-2h1a1 1 0 01.866.5H9.5A1.5 1.5 0 0011 12h-1a1 1 0 01-1-1V8a1 1 0 011-1h1V6a5 5 0 00-5-5z"
    ></path>
  </svg>
);

export const ImportIcon: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width || "20"} height={height || "20"} fill="none" viewBox="0 0 20 20">
    <path
      fill={color || "#0973BA"}
      fillRule="evenodd"
      d="M4.375 7.5a.625.625 0 00-.625.625v10a.625.625 0 00.625.625h11.25a.624.624 0 00.625-.625v-10a.625.625 0 00-.625-.625h-2.5a.625.625 0 110-1.25h2.5A1.875 1.875 0 0117.5 8.125v10A1.875 1.875 0 0115.625 20H4.375A1.875 1.875 0 012.5 18.125v-10A1.875 1.875 0 014.375 6.25h2.5a.625.625 0 010 1.25h-2.5z"
      clipRule="evenodd"
    ></path>
    <path
      fill={color || "#0973BA"}
      fillRule="evenodd"
      d="M9.557 14.818a.625.625 0 00.886 0l3.75-3.75a.626.626 0 10-.886-.886l-2.682 2.684V1.875a.625.625 0 10-1.25 0v10.991l-2.683-2.684a.626.626 0 10-.885.886l3.75 3.75z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const AddIcon: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width || "16"} height={height || "16"} fill="none" viewBox="0 0 12 12">
    <path
      fill={color || "#fff"}
      fillRule="evenodd"
      d="M6 0a.5.5 0 01.5.5v5h5a.5.5 0 010 1h-5v5a.5.5 0 01-1 0v-5h-5a.5.5 0 010-1h5v-5A.5.5 0 016 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const EditIcon: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1195_1612)">
      <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
      <g clipPath="url(#clip1_1195_1612)">
        <path
          fill="#05386B"
          d="M12.146.146a.5.5 0 01.707 0l3 3a.5.5 0 010 .707l-10 10a.499.499 0 01-.168.11l-5 2a.5.5 0 01-.65-.65l2-5a.5.5 0 01.111-.167l10-10zm-.94 2.353L13.5 4.792 14.793 3.5 12.5 1.206 11.207 2.5zm1.587 3L10.5 3.206 4 9.706V10h.5a.5.5 0 01.5.5v.5h.5a.5.5 0 01.5.5v.5h.293l6.5-6.5zM3.03 10.675l-.105.105-1.529 3.822 3.822-1.529.105-.106A.5.5 0 015 12.5V12h-.5a.5.5 0 01-.5-.5V11h-.5a.5.5 0 01-.469-.324z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1195_1612">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
      <clipPath id="clip1_1195_1612">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const DeleteIcon: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1195_1591)">
      <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
      <g clipPath="url(#clip1_1195_1591)">
        <path
          fill="#05386B"
          d="M6.5 1h3a.5.5 0 01.5.5v1H6v-1a.5.5 0 01.5-.5zM11 2.5v-1A1.5 1.5 0 009.5 0h-3A1.5 1.5 0 005 1.5v1H1.5a.5.5 0 100 1h.538l.853 10.66A2 2 0 004.885 16h6.23a2 2 0 001.994-1.84l.853-10.66h.538a.5.5 0 000-1H11zm1.958 1l-.846 10.58a1 1 0 01-.997.92h-6.23a1 1 0 01-.997-.92L3.042 3.5h9.916zm-7.487 1a.5.5 0 01.528.47l.5 8.5a.5.5 0 01-.998.06L5 5.03a.5.5 0 01.47-.53zm5.058 0a.5.5 0 01.47.53l-.5 8.5a.5.5 0 11-.998-.06l.5-8.5a.5.5 0 01.528-.47zm-2.53 0a.5.5 0 01.5.5v8.5a.5.5 0 11-1 0V5a.5.5 0 01.5-.5z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1195_1591">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
      <clipPath id="clip1_1195_1591">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const DuplicateIcon: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1195_619)">
      <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
      <g clipPath="url(#clip1_1195_619)">
        <path
          fill="#05386B"
          d="M13 0H6a2 2 0 00-2 2 2 2 0 00-2 2v10a2 2 0 002 2h7a2 2 0 002-2 2 2 0 002-2V2a2 2 0 00-2-2zm0 13V4a2 2 0 00-2-2H5a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1zM3 4a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V4z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1195_619">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
      <clipPath id="clip1_1195_619">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const WarningIcon: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 15"
      fill={color || "currentColor"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.98201 1.56601C8.88302 1.39358 8.74028 1.25031 8.56821 1.15069C8.39614 1.05106 8.20084 0.998596 8.00201 0.998596C7.80318 0.998596 7.60787 1.05106 7.4358 1.15069C7.26373 1.25031 7.121 1.39358 7.02201 1.56601L0.165007 13.233C-0.291993 14.011 0.256007 15 1.14501 15H14.858C15.747 15 16.296 14.01 15.838 13.233L8.98201 1.56601ZM8.00001 5.00001C8.53501 5.00001 8.95401 5.46201 8.90001 5.99501L8.55001 9.50201C8.53825 9.63978 8.47521 9.76813 8.37336 9.86165C8.27152 9.95517 8.13828 10.0071 8.00001 10.0071C7.86173 10.0071 7.7285 9.95517 7.62665 9.86165C7.52481 9.76813 7.46177 9.63978 7.45001 9.50201L7.10001 5.99501C7.08744 5.86925 7.10135 5.74224 7.14085 5.62218C7.18035 5.50212 7.24456 5.39166 7.32935 5.29792C7.41414 5.20419 7.51762 5.12925 7.63313 5.07794C7.74864 5.02663 7.87361 5.00008 8.00001 5.00001ZM8.00201 11C8.26722 11 8.52158 11.1054 8.70911 11.2929C8.89665 11.4804 9.00201 11.7348 9.00201 12C9.00201 12.2652 8.89665 12.5196 8.70911 12.7071C8.52158 12.8947 8.26722 13 8.00201 13C7.73679 13 7.48244 12.8947 7.2949 12.7071C7.10736 12.5196 7.00201 12.2652 7.00201 12C7.00201 11.7348 7.10736 11.4804 7.2949 11.2929C7.48244 11.1054 7.73679 11 8.00201 11Z" />
    </svg>
  );
};

export const CheckCircle: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill={color || "currentColor"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967C10.9626 4.97674 10.9559 4.98424 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967Z" />
    </svg>
  );
};

export const FeatureDisabledSubscriptionSvg: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => {
  return (
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="100" cy="100" r="100" fill="#E6EBF0" />
      <path
        d="M76.5521 72.2272C76.9031 71.507 76.955 70.6763 76.6962 69.9178C76.4375 69.1592 75.8894 68.535 75.1725 68.1824C74.4556 67.8298 73.6286 67.7777 72.8735 68.0376C72.1183 68.2975 71.4969 68.8481 71.1459 69.5682L42.3823 128.569C41.1792 131.03 37.9707 137.573 43.5855 142.069C51.9675 148.765 75.75 152.117 99.5405 152.113C123.331 152.109 147.11 148.765 155.476 142.069C161.09 137.573 157.882 131.026 156.679 128.569L119.268 51.7935C117.46 48.0864 114.652 44.9633 111.165 42.7789C107.678 40.5946 103.65 39.4366 99.5405 39.4366C95.4308 39.4366 91.4035 40.5946 87.9161 42.7789C84.4288 44.9633 81.6213 48.0864 79.8127 51.7935C79.4612 52.5132 79.4086 53.3436 79.6666 54.1021C79.9245 54.8607 80.4719 55.4852 81.1883 55.8384C81.9047 56.1915 82.7314 56.2443 83.4865 55.9852C84.2416 55.726 84.8633 55.1761 85.2149 54.4565C86.5272 51.7634 88.5656 49.4943 91.0981 47.9071C93.6306 46.32 96.5556 45.4786 99.5405 45.4786C102.525 45.4786 105.451 46.32 107.983 47.9071C110.516 49.4943 112.554 51.7634 113.866 54.4565L151.301 131.228C153.394 135.522 152.732 136.538 151.726 137.343C137.095 149.059 61.9818 149.059 47.3514 137.343C46.3487 136.538 45.687 135.526 47.7845 131.228L76.5521 72.2272Z"
        fill="#B4C3D3"
      />
      <path
        d="M104.225 104.464V76.7568C104.225 75.8239 103.78 74.9293 102.988 74.2696C102.195 73.61 101.121 73.2394 100 73.2394C98.8794 73.2394 97.8046 73.61 97.0122 74.2696C96.2198 74.9293 95.7747 75.8239 95.7747 76.7568V104.464C95.7747 105.397 96.2198 106.291 97.0122 106.951C97.8046 107.611 98.8794 107.981 100 107.981C101.121 107.981 102.195 107.611 102.988 106.951C103.78 106.291 104.225 105.397 104.225 104.464Z"
        fill="#B4C3D3"
      />
      <path
        d="M93.8967 122.066C93.8967 123.18 94.2546 124.269 94.925 125.195C95.5954 126.122 96.5483 126.844 97.6632 127.27C98.7782 127.697 100.005 127.809 101.189 127.592C102.373 127.375 103.46 126.838 104.314 126.051C105.168 125.264 105.749 124.26 105.985 123.168C106.221 122.075 106.101 120.942 105.64 119.913C105.179 118.883 104.397 118.003 103.394 117.384C102.391 116.764 101.212 116.433 100.005 116.432C98.3859 116.432 96.833 117.025 95.6876 118.082C94.5422 119.138 93.8981 120.571 93.8967 122.066Z"
        fill="#B4C3D3"
      />
    </svg>
  );
};

export const FeatureDisabledSvg: React.FC<IconProps> = ({ title, className, width, height, color, disabled }) => {
  return (
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="100" cy="100" r="100" fill="#E6EBF0" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M134.19 64.8561C138.762 69.4284 142.373 74.8686 144.812 80.857C147.788 88.2009 148.917 96.1631 148.096 104.044C147.275 111.925 144.531 119.484 140.106 126.057C135.68 132.629 129.708 138.014 122.715 141.739C115.722 145.464 107.921 147.414 99.9985 147.418C89.8864 147.426 80.0265 144.261 71.808 138.369C63.5895 132.477 57.4267 124.154 54.188 114.573C51.9013 107.777 51.1423 100.559 51.9652 93.4347C52.0408 92.7868 52.3707 92.1956 52.8822 91.7909C53.3938 91.3863 54.0451 91.2015 54.6929 91.2772C55.3407 91.3529 55.9319 91.6828 56.3364 92.1944C56.741 92.706 56.9258 93.3573 56.8501 94.0052C56.1119 100.406 56.7936 106.892 58.8467 113C60.8079 118.773 63.9594 124.068 68.0954 128.543C68.4911 128.008 68.9491 127.477 69.4528 126.973L128.192 68.2339C128.602 67.8244 129.03 67.4451 129.463 67.105C125.636 63.5779 121.194 60.7565 116.342 58.7903C105.854 54.5705 94.1401 54.5705 83.6519 58.7903C78.2672 60.9715 73.3785 64.218 69.2789 68.335C69.0505 68.5634 68.7794 68.7446 68.481 68.8683C68.1825 68.9919 67.8627 69.0555 67.5397 69.0555C67.2167 69.0555 66.8968 68.9919 66.5984 68.8683C66.3 68.7446 66.0288 68.5634 65.8004 68.335C65.572 68.1066 65.3909 67.8354 65.2673 67.537C65.1436 67.2385 65.08 66.9186 65.08 66.5956C65.08 66.2725 65.1436 65.9527 65.2673 65.6542C65.3909 65.3558 65.572 65.0846 65.8004 64.8561C70.3644 60.2744 75.8061 56.6611 81.7995 54.2326C93.474 49.5281 106.517 49.5281 118.191 54.2326C124.179 56.6719 129.618 60.2839 134.19 64.8561ZM132.908 70.6652C133.09 70.8757 133.27 71.088 133.447 71.3022C138.029 76.8217 141.171 83.3902 142.595 90.4208C144.018 97.4514 143.679 104.725 141.605 111.592C139.532 118.46 135.791 124.706 130.715 129.774C126.614 133.889 121.726 137.134 116.342 139.316C105.855 143.541 94.1391 143.541 83.6519 139.316C79.244 137.522 75.1696 135.021 71.5853 131.92C72.0983 131.536 72.607 131.094 73.0904 130.611L131.83 71.8715C132.219 71.4822 132.581 71.0764 132.908 70.6652Z"
        fill="#B4C3D3"
        stroke="#B4C3D3"
        stroke-width="3"
      />
    </svg>
  );
};
